.pdfContainer {
  max-height: 70vh;
  overflow-y: scroll;
  width: 60vw;
}

.pdfInCarousel {
  max-height: 70vh;
  overflow-y: scroll;
  /* width: 700px; */
}

.react-pdf__Page__canvas {
  width: 100% !important;
  height: auto !important;
}
