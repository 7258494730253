.sendButton {
  position: absolute;
  right: 10px;
  bottom: 10px;
}

.messagesContainer::-webkit-scrollbar,
.hide-scrollbar::-webkit-scrollbar {
  display: none;
}

.attachFileInMessage {
  width: 150px;
  height: 100px;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  background-color: #c4c4c4;
}
.attachFileInMessagewithText {
  overflow: hidden;
  width: 150px;
}

.attachFileInMessage .react-pdf__Page__canvas,
.react-pdf__Page__annotations,
.react-pdf__Page__textContent {
  display: none;
  height: 100px !important;
  width: 100% !important;
}

.question-mark {
  padding-bottom: 1px;
}
