@import url("https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");

.App {
  text-align: center;
  transition: background-color 0.2s ease-in-out;

  background-color: #312767;
}

/* @media only screen and (max-width: 1600px) {
  .lightconfirmationModal {
    zoom: 60% !important;
  }
  .darkconfirmationModal {
    zoom: 60% !important;
  }
  .darksideBarContainer {
    zoom: 70% !important;
  }
  .lightsideBarContainer {
    zoom: 70% !important;
  }
  .popoverContainer {
    zoom: 73%;
  }
  .f13 {
    font-size: 11px !important;
  }
} */
.borderTest {
  border: 2px solid red;
}

.h70px {
  height: 70px;
}

.mt70px {
  margin-top: 80px;
}

.t-02 {
  transition: all 0.2s ease !important;
}

.transition08 {
  transition: all 0.8s ease !important;
}

.flex-1 {
  flex: 1;
}

.pointer {
  cursor: pointer;
}

.disable-pointer {
  pointer-events: none;
}

.light-App {
  background-color: #f4f6fd;
}

.h24 {
  height: 24px !important;
}

.h100 {
  height: 100% !important;
}

.dark-App {
  background-color: #0a122e;
}

svg * {
  cursor: pointer;
  transition: fill 0.2s ease-in-out;
}

* {
  font-family: Poppins;
}
.firstLetterCapital::first-letter {
  text-transform: uppercase !important;
}

.alignH {
  display: flex;
  align-items: center;
}
.grid3 {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  /* 4 items per row */
  column-gap: 1%;
  /* Spacing between items */
  row-gap: 3%;
}

.grid4 {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  /* 4 items per row */
  column-gap: 1%;
  /* Spacing between items */
  row-gap: 10px;
}

.alignC {
  display: flex;
  align-items: center;
  justify-content: center;
}

.directionC {
  display: flex;
  flex-direction: column;
}

a {
  color: unset !important;
  text-decoration: none !important;
}

.scrollbarY {
  overflow-y: scroll !important;
}

.fitContent {
  min-width: max-content;
}

/********************* STYLE SCROLLBAR **************************/
::-webkit-scrollbar {
  width: 4px;
}

.light-App ::-webkit-scrollbar-track {
  background: #f4f6fd;
}

.light-App ::-webkit-scrollbar-thumb {
  background: #000000;
  border-radius: 12px;
}

.dark-App ::-webkit-scrollbar-track {
  background: #0a122e;
}

.dark-App ::-webkit-scrollbar-thumb {
  background: #ffffff;
  border-radius: 12px;
}

/********************* CUSTOM FONT-WEIGHT **************************/

.lighter {
  font-weight: 200;
}

.light {
  font-weight: 300;
}

.regular {
  font-weight: 400;
}

.bold {
  font-weight: 500;
}

.bolder {
  font-weight: 600;
}

.italic {
  font-style: italic;
  text-align: justify;
}

.sub_standard {
  font-size: 18px !important;
}

.sub_italique {
  font-size: 18px !important;
  font-style: italic;
}

.sub_gras {
  font-size: 18px !important;
  font-weight: bold;
}

.ques_title {
  font-size: 17px !important;
}

.ques_gras {
  font-weight: bold;
}

.xbold {
  font-weight: 700;
}

.text-ellipsis {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.modalContainer {
  max-height: 94vh;
  overflow-y: scroll;
  width: 100%;
  margin: auto;
}

/********************* CUSTOM FONT-SIZE **************************/

.f6 {
  font-size: 6px;
}

.f8 {
  font-size: 8px;
}

.f10 {
  font-size: 10px;
}

.f11 {
  font-size: 11px;
}

.f12 {
  font-size: 12px;
}

.f13 {
  font-size: 13px;
}

.f14 {
  font-size: 14px;
}

.f16 {
  font-size: 16px;
}

.f18 {
  font-size: 18px;
}

.f20 {
  font-size: 20px;
}

.f22 {
  font-size: 22px;
}

.f24 {
  font-size: 24px;
}

.f26 {
  font-size: 26px;
}

.f28 {
  font-size: 28px;
}

.f30 {
  font-size: 30px;
}

.f32 {
  font-size: 32px;
}

.f36 {
  font-size: 36px;
}

.f40 {
  font-size: 40px;
}

.f48 {
  font-size: 48px;
}

.f60 {
  font-size: 60px;
}

.f96 {
  font-size: 96px;
}

.custom-font-size {
  font-size: 14px;
}

.custom-date-font-size {
  font-size: 12px;
}

.custom-ticket-font-size {
  font-size: 12px;
}

.custom-title-font-size {
  font-size: 18px;
}

.custom-bold-font-size {
  font-size: 24px;
}

@media (max-width: 1200px) {
  .custom-bold-font-size {
    font-size: 20px;
  }

  .custom-title-font-size {
    font-size: 16px;
  }

  .custom-font-size {
    font-size: 12px;
  }

  .custom-date-font-size {
    font-size: 10px;
  }

  .custom-ticket-font-size {
    font-size: 10px;
  }
}

@media (max-width: 992px) {
  .custom-bold-font-size {
    font-size: 18px;
  }

  .custom-title-font-size {
    font-size: 14px;
  }

  .custom-font-size {
    font-size: 12px;
  }

  .custom-date-font-size {
    font-size: 8px;
  }
}

.radius05 {
  border-radius: 5px;
}

.radius06 {
  border-radius: 6px;
}

.radius08 {
  border-radius: 8px;
}

.radius10 {
  border-radius: 10px;
}
.radius10-top {
  border-radius: 10px 10px 0 0;
}
.radius10-bottom {
  border-radius: 0 0 10px 10px;
}

.radius20 {
  border-radius: 20px;
}

.radius50 {
  border-radius: 50%;
}

.w2 {
  width: 2% !important;
}

.w5 {
  width: 5% !important;
}

.w7 {
  width: 7% !important;
}

.w10 {
  width: 10% !important;
}

.w15 {
  width: 15% !important;
}

.w20 {
  width: 20% !important;
}

.w25 {
  width: 25% !important;
}

.w27 {
  width: 27% !important;
}

.w30 {
  width: 30% !important;
}

.w32 {
  width: 32% !important;
}

.w35 {
  width: 35% !important;
}

.w40 {
  width: 40% !important;
}

.w45 {
  width: 45% !important;
}

.w49 {
  width: 49% !important;
}

.w50 {
  width: 50% !important;
}

.w60 {
  width: 60% !important;
}

.w65 {
  width: 65% !important;
}

.w67 {
  width: 67% !important;
}

.w70 {
  width: 70% !important;
}

.w80 {
  width: 80% !important;
}

.w85 {
  width: 85% !important;
}

.w88 {
  width: 88%;
}

.w90 {
  width: 90% !important;
}

.w95 {
  width: 95% !important;
}

.w98 {
  width: 98%;
}

.wContent {
  min-width: fit-content;
}

.h10px {
  height: 10px !important;
}

.h10 {
  height: 10% !important;
}

.h20 {
  height: 20% !important;
}

.h30px {
  height: 30px !important;
}

.h40 {
  height: 40px !important;
}

.h70 {
  height: 70px !important;
}

.h50px {
  height: 50px !important;
}

.h50 {
  height: 50% !important;
}

.h60 {
  height: 60% !important;
}

.h60px {
  height: 60px !important;
}

.h70 {
  height: 70%;
}

.h75 {
  height: 75%;
}

.h80 {
  height: 80% !important;
}

.h85 {
  height: 85% !important;
}

.h87 {
  height: 87% !important;
}

.h90 {
  height: 90%;
}

.h130 {
  height: 130px;
}

.h352 {
  height: 352px;
}

.mx-80vh {
  max-height: 80vh !important;
}

.y-mandatory {
  scroll-snap-type: y mandatory;
  /* max-height: calc(80vh); */
  scroll-behavior: smooth;
  overflow-y: auto;
}

::-webkit-scrollbar {
  width: 00px;
}

*::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
* {
  -ms-overflow-style: none;
  /* IE and Edge */
  scrollbar-width: none;
  /* Firefox */
}

.showScroll::-webkit-scrollbar {
  width: 12px;
}

.showScroll::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/*  */

/* For Firefox */
.showScroll {
  scrollbar-width: thin;
  scrollbar-color: #b3b3b4 #f8f6f6;
  -ms-overflow-style: auto !important;
  /* IE and Edge */
  scrollbar-width: thin !important;
  /* thumb color and track color */
}

.showScroll::-webkit-scrollbar-track {
  background: #f1f1f1;
}
.hideScrollbar::-webkit-scrollbar {
  display: none;
}

/*  */

/* For Firefox */
.showScroll {
  scrollbar-width: thin;
  scrollbar-color: #b3b3b4 #f8f6f6;
  -ms-overflow-style: auto !important;
  /* IE and Edge */
  scrollbar-width: thin !important;
  /* thumb color and track color */
}

.y-mandatory > * {
  scroll-snap-align: start;
  min-height: min-content;
}

.max-90vh {
  height: 90vh;
  max-height: 90vh;
}

.h-65vh {
  height: 65vh;
  overflow-y: auto;
}

button {
  min-width: fit-content;
}

@media screen and (min-width: 1400px) {
  /* .container {
    max-width: 1750px !important;
  } */
}

.ticket-container {
  max-width: 1200px;
  margin: 0 auto;
}

/********************* CUSTOM COLOR CHECKBOX **************************/
.z-index-0 {
  z-index: 0;
}

.z-index-1 {
  z-index: 1;
}

.z-index-2 {
  z-index: 2;
}

.tooltip {
  position: fixed !important;
  --bs-tooltip-zindex: 1 !important;
}

.tooltip[data-popper-reference-hidden="true"] {
  visibility: hidden;
  pointer-events: none;
}

.accent-color-yellow {
  accent-color: rgba(var(--bs-yellow-rgb));
}

.tooltip-inner {
  background-color: var(--bs-dark-grey-bg) !important;
}

.bs-tooltip-bottom .tooltip-arrow::before {
  border-bottom-color: var(--bs-dark-grey-bg) !important;
}

.ob-contain {
  object-fit: contain;
}

.ob-cover {
  object-fit: cover;
}

.px-30 {
  padding-left: 30px;
  padding-right: 30px;
}

.w-24 {
  min-width: 28px;
}

.show-pdf {
  visibility: visible;
}

.hide-pdf {
  visibility: hidden;
}

.absolute-center {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.min-w-200 {
  min-width: 200px !important;
}

.max-w-200 {
  max-width: 200px !important;
}

.min-fit-content {
  min-width: fit-content !important;
}

.no-resize {
  resize: none;
}

.avatar-select-img {
  width: 20px;
  height: 20px;
  border-radius: 50%;
}

.svg-icon:hover {
  transform: scale(1.1);
}

.underline-hover:hover {
  text-decoration: underline;
}

.clickable-text {
  transition: all 0.2s ease-in-out;
  transform: scale(1);
}

.clickable-text:hover {
  transform: scale(1.02);
}

.overflow-y-auto {
  overflow-y: auto;
}

.tooltip-item:hover {
  background-color: #59638b26;
}

.bg-tooltip-item {
  background-color: #59638b26;
}

.overflow-y-scroll {
  overflow-y: scroll;
}

.break-word {
  word-break: break-word;
}

.participant-ticket-added {
  background-color: #3365ff40;
}

.participant-ticket-removed {
  background-color: #ff2c2c40;
}

.icon-added-removed {
  min-width: 22px;
}

@import url(./Styles/hover.css);
