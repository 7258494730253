.dropDown-menu {
  width: 300px;
  height: 477;
  top: 80px;
  box-shadow: 0 6px 20px 0 rgba(7, 13, 36, 0.15) !important;
}

.lightDropDown {
  border: 1px solid #a9bcd3;
}

.darkDropDown {
  border: 1px solid #262c45;
}
