#unity-container {
  position: relative;
  overflow: hidden;
  height: 100%;
  width: 100%;
}

#unity-canvas {
  width: 100%;
  height: 100%;

  background-color: rgba(200, 200, 200, 0.5);
}

#unity-loading-bar {
  position: absolute;
  top: 0%;
  left: 0%;
  right: 0%;
  bottom: 40px;
  display: none;
}

#unity-loading-container {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

#eleance-logo {
  width: 300px;
  height: 150px;
  background: url("./artefactoAssets/eleance-logo.png");
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
}

#unity-progress-bar-empty {
  width: 50%;
  height: 20px;
  background: url("./artefactoAssets/progress-bar-empty-dark.png");
  background-size: inherit;
  background-repeat: no-repeat;
  background-position: center;
}

#unity-progress-bar-full {
  width: 0%;
  height: 20px;
  background: url("./artefactoAssets/progress-bar-full-dark.png");
  background-size: inherit;
  background-repeat: no-repeat;
  background-position: center;
}
#fb_popup_background.highlight {
  border: 3px dashed #ccc;
  border-radius: 26px;
  border-color: aquamarine;
}

#unity-footer {
  position: relative;
}

.unity-mobile #unity-footer {
  display: none;
}

#unity-build-title {
  float: right;
  margin-right: 10px;
  line-height: 38px;
  font-family: arial;
  font-size: 18px;
}

#unity-fullscreen-button {
  position: fixed;
  bottom: 0;
  right: 0;
  width: 38px;
  height: 38px;
  background: url("./artefactoAssets/fullscreen-button.png") no-repeat center;
}

#unity-warning {
  position: absolute;
  left: 50%;
  top: 5%;
  transform: translate(-50%);
  background: white;
  padding: 10px;
  display: none;
}
