.coloredButton {
  outline: none;
}

.scaledColoredButton {
  transform: scale(0.94);
}

.scaledColoredButton:hover {
  transform: scale(0.95);
}

.loginButton:hover {
  transform: scale(1.02);
}

.coloredButton:active {
  transform: scale(1);
  opacity: 0.5;
}

.coloredButton:disabled {
  opacity: 0.3;
}
.scaledAttachement {
  transform: scale(0.98);
}

.scaledAttachement:hover {
  transform: scale(1);
}
