.myNotif {
  z-index: 500;
  position: fixed;
  top: 30px;
  margin-left: auto;
  margin-right: auto;
  left: 0;
  right: 0;
  text-align: center;
}
.btn-close {
  background: transparent url("../../../Images/closButton.svg") no-repeat center;
}
