.PieChartChart {
  height: 310px !important;
  width: 100%;
}
.legendBar {
  height: 12px !important;
  width: 12px !important;
  min-height: 12px !important;
  min-width: 12px !important;
  border-radius: 50%;
}

.customSelectCharts {
  background: transparent;
  border: none;
  outline: none;
}

@media (max-width: 1100px) {
  .scaleN-md {
    transform: scale(0.9);
  }
}
