.OrgBarContainer {
  width: 60px !important;
}
.organisationItemTooltip {
  z-index: 10;
}
.lightOrgBarContainer {
  border-right: 1px solid rgb(241, 241, 241);
}

.organisationIcon {
  width: 48px;
  height: 48px;
  background-color: #f4f6fd;
  border-radius: 50%;
  z-index: 100;
}
.orgLogo {
  width: 34px;
}
.notificationOrganisation {
  width: 12px;
  height: 12px;

  background-color: #fc372b;
  position: absolute;
  top: 0px;
  right: 0px;
  z-index: 2;
  border-radius: 50%;
}
.organisationList {
  max-height: 50vh !important;
}
