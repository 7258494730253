.addIcon {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 40px;
  height: 40px;
}

.addIcon:hover {
  transform: scale(1.05);
  background-color: #59638b26;
}

.colonneItem {
  width: 24px;
  height: 24px;
  border: 1.5px solid #a9bcd3;
  border-radius: 5px;
  margin: 1px;
}

.selectedColonne .colonneItem {
  background-color: #a9bcd3;
  border: 1.5px solid #354074;
}
.colonneContainer:hover .colonneItem {
  background-color: #a9bcd3;
  border: 1.5px solid #354074;
}

/* Switch container */
.switch-container {
  display: inline-block;
  position: relative;
  width: 40px;
  height: 22px;
}

/* Slider (the circle that moves) */
.switch-slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  transition: 0.4s;
}

/* Styles when the switch is in the "on" state */
.switch-slider:before {
  position: absolute;
  content: "";
  height: 16px;
  width: 16px;
  left: 3px;
  bottom: 3px;
  border-radius: 50%;
  background-color: blue;
  transition: 0.4s;
}

/* Styles when the switch is in the "off" state */
.switch-slider.off:before {
  transform: translateX(18px);
}
.switch-slider.on:before {
  background-color: #a9bcd3;
}

/* Default background color for the switch */
.switch-slider.off {
  background-color: #f8f9ff;
  border-radius: 50px;
}

/* Background color for the switch when it's turned on */
.switch-slider.on {
  background-color: #f8f9ff;
  border-radius: 50px;
}
