.buttonStatus {
  border: none !important;
  cursor: default !important;
}

.btn-wrap-text {
  overflow: hidden;
  white-space: nowrap;
  display: inline-block;
  text-overflow: ellipsis;
}
