.icon-btn {
  width: 40px;
  height: 40px;
}

.icon-btn:hover {
  transform: scale(1.05);
}

.light-button-clicked {
  background: rgb(53 64 116);
}

@media (max-width: 1200px) {
  .icon-btn {
    width: 35px;
    height: 35px;
  }
}

.click-animation:active {
  transform: scale(1.1);
  opacity: 0.5;
}

.click-animation:disabled {
  transform: unset;
  opacity: 0.3;
}
