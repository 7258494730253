.fc-scrollgrid-section-header > * {
  border: none !important;
}

.fc .fc-scrollgrid-liquid {
  border: none !important;
}

.fc-col-header-cell,
.fc-timegrid-axis {
  border-bottom: 1px solid #35407433 !important;
  border-left: none !important;
  border-right: none !important;
}

.fc-direction-ltr .fc-timegrid-col-events {
  margin: 0;
}

/* .fc .fc-timegrid-col.fc-day-today,
.fc-daygrid-day.fc-day-today {
  background-color: red !important;
} */

.fc-dayGridMonth-view {
  position: absolute !important;
}

.fc-daygrid-day-number {
  font-size: 12px;
  font-weight: 500;
  color: #59638b !important;
}

.fc-timegrid-event-harness-inset .fc-timegrid-event {
  box-shadow: none !important;
}

.fc-day-mon {
  z-index: 7 !important;
  position: relative !important;
}

.fc-day-tue {
  z-index: 6 !important;
  position: relative !important;
}

.fc-day-wed {
  z-index: 5 !important;
  position: relative !important;
}

.fc-day-thu {
  z-index: 4 !important;
  position: relative !important;
}

.fc-day-fri {
  z-index: 3 !important;
  position: relative !important;
}

.fc-day-sat {
  position: relative !important;
  z-index: 2 !important;
}

.fc-day-sun
  .fc-timegrid-col-frame
  .fc-timegrid-col-events
  .fc-timegrid-event-harness:nth-child(1) {
  z-index: 10 !important;
}

.fc .fc-timegrid-col.fc-day-today,
.fc-daygrid-day.fc-day-today {
  background-color: transparent !important;
}

.fc-day-today .fc-col-header-cell-cushion {
  border-bottom: 3px solid #3365ff !important;
}

.fc-dayGridMonth-view {
  position: absolute !important;
}

.fc-daygrid-day-number {
  font-size: 12px;
  font-weight: 500;
  color: #59638b !important;
}

.fc-timegrid-event-harness-inset .fc-timegrid-event {
  box-shadow: none !important;
}

.fc .fc-timegrid-slot-minor {
  border-top: none;
}

.fc .fc-timegrid-slot-lane {
  height: 2.5em;
}

.fc-scrollgrid-sync-inner {
  margin: 3px 0;
}

.fc .fc-timegrid-slot-label-cushion {
  color: #a9bcd3;
  font-size: 12px;
  font-weight: 500;
  line-height: 18px;
}

.fc .fc-timegrid-slot-label {
  position: relative;
  top: -16px;
  padding-right: 5px;
}

.light-calendar .fc-timegrid-slot-label {
  background-color: white;
}

.dark-calendar .fc-timegrid-slot-label {
  background-color: #181f3a;
}

.fc-col-header-cell-cushion {
  font-size: 14px;
  font-weight: 500;
}

.light-calendar .fc-col-header-cell-cushion {
  color: #354074 !important;
}

.dark-calendar .fc-col-header-cell-cushion {
  color: #a9bcd3 !important;
}

.fc-theme-standard .fc-scrollgrid {
  border: none;
}

.fc-theme-standard td {
  border-right: none;
  border-bottom: none;
  border-top: 1px solid #35407433;
  /* border-top: none; */
  border-left: 1px solid #35407433;
  height: 30px !important;
}

.fc-event-main {
  cursor: pointer;
}

.selectedCalendarCard {
  box-shadow: inset 0px 0px 0px 2px #3365ff;
  border-radius: 5px;
}

.eventColorPicker {
  border-radius: 50%;
  width: 16px;
  height: 16px;
}

thead {
  position: sticky !important;
  top: 60px !important;
  z-index: 5;
}

.light-calendar th {
  background-color: white !important;
}

.dark-calendar th {
  background-color: #181f3a !important;
}

.light-calendar th {
  background-color: white !important;
}

.dark-calendar th {
  background-color: #181f3a !important;
}

.light-calendar th {
  background-color: white !important;
}

.dark-calendar th {
  background-color: #181f3a !important;
}

.light-calendar th {
  background-color: white !important;
}

.dark-calendar th {
  background-color: #181f3a !important;
}

.fc-more-link {
  font-size: 12px;
  font-weight: 500;
  color: #59638b !important;
  margin-bottom: 5px !important;
}

.fc-daygrid-day-frame {
  margin-bottom: 8px !important;
  height: 80px !important;
}

.avatars__item {
  margin-left: -15px;
}
.avatars__item:hover {
  z-index: 1000 !important;
}

.avatars__item:hover,
.share-btn:hover {
  z-index: 6;
  transform: scale(1.1);
  transition: all 0.2s ease-in-out;
}

.calendarList {
  width: 272px !important;
  height: 284px !important;
  top: 60px;
  right: 0;
  box-shadow: 0 6px 20px 0 rgba(7, 13, 36, 0.15) !important;
}

.lightCalendartList {
  border: 1px solid #a9bcd3;
}

.darkCalendarList {
  border: 1px solid #262c45;
}

.fc-popover {
  position: absolute !important;
  width: 300px;
  top: 100px;
  right: 0px !important;
  border-radius: 5px;
  border: 1px solid #35407433 !important;
  padding: 5px;
}

.dark-calendar + .fc-popover {
  background: #0a122e !important;
}

.dark-calendar + .fc-popover .fc-popover-title {
  color: white !important;
}

.fc-popover-title {
  font-size: 14px;
  font-weight: 500;
  color: #354074 !important;
}

.fc-popover-close {
  color: #a9bcd3;
}

.fc-popover-header {
  background: none !important;
}

.fc .fc-scroller-harness {
  overflow: scroll !important;
}

.fc-scrollgrid-shrink[data-time="00:00:00"] {
  padding-top: 15px !important;
}

@media (max-width: 1200px) {
  .fc-col-header-cell-cushion {
    font-size: 12px;
    font-weight: 500;
  }
  .fc .fc-timegrid-slot-label-cushion {
    font-size: 10px;
  }
}

/* .fc-timegrid-col-frame
  .fc-timegrid-col-events
  .fc-timegrid-event-harness:nth-child(1) {
  z-index: 100 !important;
} */

@media (max-width: 1200px) {
  .fc-col-header-cell-cushion {
    font-size: 12px;
    font-weight: 500;
  }
  .fc .fc-timegrid-slot-label-cushion {
    font-size: 10px;
  }
}

/* .fc-timegrid-col-frame
  .fc-timegrid-col-events
  .fc-timegrid-event-harness:nth-child(1) {
  z-index: 100 !important;
} */

@media (max-width: 1200px) {
  .fc-col-header-cell-cushion {
    font-size: 12px;
    font-weight: 500;
  }
  .fc .fc-timegrid-slot-label-cushion {
    font-size: 10px;
  }
}

/* .fc-timegrid-col-frame
  .fc-timegrid-col-events
  .fc-timegrid-event-harness:nth-child(1) {
  z-index: 100 !important;
} */

@media (max-width: 1200px) {
  .fc-col-header-cell-cushion {
    font-size: 12px;
    font-weight: 500;
  }
  .fc .fc-timegrid-slot-label-cushion {
    font-size: 10px;
  }
}
