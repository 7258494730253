.forgotContainer {
  height: 100vh;
  background-repeat: no-repeat;
  background-position: center;
}

.forgotInner {
  display: flex;
  flex-direction: column;
  justify-content: flex-end !important;
}

.forgotForm {
  height: 70%;
  width: 100% !important;
}

.loginImage-container {
  border-radius: 30px;
  width: fit-content;
}

.loginImage-container:active {
  box-shadow: 4px 4px #b9b9b9;
  transform: translateY(4px);
  transition-duration: 80ms;
}

.loginImage-container:active .loginImage {
  position: relative;
  top: 2px;
  left: 2px;
}

@media (max-height: 800px) {
  .forgotForm {
    height: 75%;
    width: 100% !important;
  }
  .loginImage-container {
    border-radius: 25px;
    width: fit-content;
  }
}

@media (max-height: 680px) {
  .forgotForm {
    height: 85%;
    width: 100% !important;
  }
  .loginImage-container {
    border-radius: 20px;
    width: fit-content;
  }
}

@media (max-height: 580px) {
  .forgotForm {
    height: 87%;
    width: 100% !important;
  }
  .loginImage-container {
    border-radius: 12px;
    width: fit-content;
  }
}

.forgotInput {
  width: 100%;
  outline: none;
  border-radius: 5px;
  border: none;
  height: 45px;
}
