.drawerContainer {
  width: 50px;
  display: flex;
  height: 100vh;
  flex-direction: column;
  position: sticky !important;
  top: 0;
  left: 0;
}
.drawerTextInner {
  overflow: hidden;
  opacity: 0;
  width: 0;
}

.drawerContainer:hover {
  width: 100px;
}
.drawerContainer:hover .drawerTextInner {
  opacity: 1;
  width: 70px;
}

.logoNavigations {
  max-height: 100vh;
  overflow: scroll;
}
.DrawerBlue {
  filter: invert(30%) sepia(100%) saturate(10000%) hue-rotate(230deg)
    brightness(120%) contrast(100%);
}
.DrawerDark {
  filter: grayscale(0.21) sepia(0.09) hue-rotate(236deg) brightness(1.06)
    contrast(1.06);
}
@media screen and (min-width: 1400px) {
  .drawerContainer {
    height: 100vh !important;
  }
}
@media screen and (max-width: 600px) {
  .drawerContainer {
    width: 80px !important;
  }
}

.drawLink {
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  transition: all 0.2s ease-in-out;
  height: 70px;
}

.drawLink:hover {
  transform: scale(1.1);
}

.logoContainer {
  transition: all 0.4s ease-in-out;
}

.logoContainer:hover {
  transform: scale(1.2);
}

.bottomDrawer {
  flex: 1;
  margin-bottom: 15px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-end;
}

.logoutIcon:hover {
  transform: scale(1.05);
}
.Drawerselector {
  position: sticky;
  width: 4px;
  right: 4px;
  z-index: 200;
  height: 40px;
  border-radius: 0px 10px 10px 0px;
  background-color: #3365ff;
  transform: translateX(9px);
}
