.transparentButton {
  background-color: transparent;
  border: none;
  outline: none;
  min-width: 100px;
  border: 2px solid transparent;
}
.transparentButtonRegular:hover {
  background-color: var(--bs-light-mode) !important;
}
.transparentButtonRemove:hover {
  background-color: #ff2c2c44;
}
.transparentButton:active {
  border: 2px solid var(--bs-light-mode);
  background-color: transparent;
  transform: scale(1.05);
}
