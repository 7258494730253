.LightDarkInputContainer {
  border: none;
  outline: none;
}
.LightDarkInputContainer:focus {
  transform: scale(1.01);
}

.lightInput {
  background-color: #f4f6fd;
  color: #354074;
}

.disabled {
  color: #35407495;
}

.lightInput::placeholder {
  color: rgba(53, 64, 116, 0.8);
}

.darkInput {
  background-color: #252c45;
  color: #d5e7fa;
}

.darkInput::placeholder {
  color: #d5e7fa;
}

::-webkit-inner-spin-button {
  display: none;
}

input[type="number"] {
  -moz-appearance: textfield;
}

::-webkit-calendar-picker-indicator {
  font-size: 20px;
  background: url("../Common/Icons/calendar.svg") no-repeat;
}
