.overviewTabContainer {
  height: calc(100vh - 200px);
  overflow-y: scroll;
}
.hello {
  height: 100vh;
}

.user-map {
  text-align: center;
  position: relative;
}

.department {
  fill: #3365ff33;
  stroke: #fff;
  stroke-width: 0.5;
}

.department:hover {
  fill: #3365ff;
}
