.LoadingButtoncontainer {
  height: 100%;
  width: 100%;
  font-family: Helvetica;
  display: flex;
  justify-content: center;
  align-items: center;
}
.LoadingButtonloader {
  height: 20px;
  width: 250px;
  display: flex;
  align-items: center;
  margin: auto;
}

.LoadingButtonloader--dot {
  animation-name: loader;
  animation-timing-function: ease-in-out;
  animation-duration: 3s;
  animation-iteration-count: infinite;
  height: 15px;
  width: 15px;
  border-radius: 100%;
  background-color: black;
  position: absolute;
  border: 2px solid white;
}

.LoadingButtonloader--dot:first-child {
  background-color: #8cc759;
  animation-delay: 0.5s;
}

.LoadingButtonloader--dot:nth-child(2) {
  background-color: #8c6daf;
  animation-delay: 0.4s;
}

.LoadingButtonloader--dot:nth-child(3) {
  background-color: #ef5d74;
  animation-delay: 0.3s;
}

.LoadingButtonloader--dot:nth-child(4) {
  background-color: #f9a74b;
  animation-delay: 0.2s;
}

.LoadingButtonloader--dot:nth-child(5) {
  background-color: #60beeb;
  animation-delay: 0.1s;
}

.LoadingButtonloader--dot:nth-child(6) {
  background-color: #fbef5a;
  animation-delay: 0s;
}

.lightLoadingButtonloader--text:after {
  color: black;
}
.darkLoadingButtonloader--text:after {
  color: white;
}
@keyframes loader {
  15% {
    transform: translateX(0);
  }

  45% {
    transform: translateX(230px);
  }

  65% {
    transform: translateX(230px);
  }

  95% {
    transform: translateX(0);
  }
}
