.bigIconModal {
  position: absolute;
  margin-left: auto;
  margin-right: auto;
  left: 0;
  right: 0;
  top: -45px;
  text-align: center;
}

.modalHeader {
  border-bottom: none;
}

.modalFooter {
  border-top: none;
}

.lightconfirmationModal {
  background: rgba(0, 0, 0, 0.2);
  backdrop-filter: blur(1px);
  -webkit-backdrop-filter: blur(1px);
  border: none;
}

.darkconfirmationModal {
  background: rgba(0, 0, 0, 0.2);
  backdrop-filter: blur(1px);
  -webkit-backdrop-filter: blur(1px);
  border: none;
}

.lightconfirmationModal .modal-content {
  background: white;
  backdrop-filter: blur(30px);
  border: transparent;
  border-radius: 5px;
}

.darkconfirmationModal .modal-content {
  background: #0a122e;
  backdrop-filter: blur(30px);
  border: transparent;
  border-radius: 5px;
}

.pdfModal .modal-content {
  background: rgba(9, 12, 26, 0.9);
  backdrop-filter: blur(30px);
  border: transparent;
}

.pdfModal {
  backdrop-filter: blur(1px);
  -webkit-backdrop-filter: blur(1px);
  border: none;
}
