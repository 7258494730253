/* .cloudFileList {
  /* overflow-y: hidden !important;
  height: 450px;
  position: relative;
  margin-bottom: -280px;
} */

.cloudFileList > div {
  scroll-snap-align: start;
}

.cloudFileCardContainer {
  min-width: 210px;
  width: 12%;
  height: 190px;
  border: 2px solid transparent;
}

.cloudFileCard .react-pdf__Page__canvas,
.react-pdf__Page__annotations,
.react-pdf__Page__textContent {
  height: 120px !important;
  width: 100% !important;
}

.cloudFileCard {
  width: 100%;
  height: 120px;

  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
}

.diffusionCardContainer {
  min-width: 230px;
  width: 230px;
}

.diffusionCard .react-pdf__Page__canvas,
.react-pdf__Page__annotations,
.react-pdf__Page__textContent {
  height: 250px !important;
  width: 100% !important;
}

.diffusionCard {
  width: 100%;
  height: 250px;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
}
.diffusionCard:hover {
  transform: scale(1.02);
}

.break-word {
  word-break: break-word;
}
@media (max-width: 1200px) {
  .cloudFileCardContainer {
    min-width: 160px;
    width: 160px;
  }
  .cloudFileCard {
    height: 90px;
  }
}
