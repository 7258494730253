$theme-colors: (
  "red": #ff2c2c,
  "green": #0cce91,
  "orange": #e67e22,
  "yellow": #ffbc20,
  "purple": #7e25ff,
  "light-purple": #302665,
  "dark-purple": #9a4ee6,
  "black-purple": #20194a,
  "blue": #3498db,
  "light-blue": #3365ff,
  "lighter-blue": #d5e7fa,
  "dark-blue": #354074,
  "blue-grey": #59638b,
  "light-blue-grey": #bbc5ee,
  "dark-green": #2ecc71,
  "pink": #e73ca3,
  "white": #ffff,
  "light-grey": #f2f2f2,
  "light-text": #a9bcd3,
  "dark-grey-bg": #181f3a,
  "dark-grey": #86909c,
  "dark-mode": #0a122e,
  "light-mode": #f4f6fd,
  "dark-message": #252c45,
  "light-message": #414a6e,
  "off-white": #fffffff1,
  "light-gray": #f8f9ff,
  "light-red": #ff7f7f,
  "light-red-transparent": #fee2e2,
  "lighter-red": #ffe5e5,
  "turquoise": #17dccd,
  "oneDriveBlue": #28a8ea,
  "disabled": #ced4da,
);
$form-range-thumb-width: 1rem;
$form-range-thumb-border: 6px;

@import "~bootstrap/scss/bootstrap.scss";
