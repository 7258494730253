.resetContainer {
  height: 100vh;
  background-repeat: no-repeat;
  background-position: center;
}

.resetInner {
  display: flex;
  flex-direction: column;
  justify-content: flex-end !important;
}

.resetForm {
  height: 70%;
  width: 100% !important;
}

@media (max-height: 800px) {
  .resetForm {
    height: 75%;
    width: 100% !important;
  }
}

@media (max-height: 680px) {
  .resetForm {
    height: 85%;
    width: 100% !important;
  }
}

@media (max-height: 580px) {
  .resetForm {
    height: 87%;
    width: 100% !important;
  }
}

.rights {
  width: 100%;
  align-self: flex-end !important;
}

.resetInput {
  display: flex;
  width: 100%;
  background-color: #f4f6fd !important;
  outline: none;
  border-radius: 4px;
  border: none;
  height: 45px;
}

.input-content {
  display: flex;
  justify-content: flex-end;
}

.input-content .inputIcons {
  position: absolute;
  margin: 10px;
}

/* @media only screen and (max-width: 1400px) {
  .resetContainer {
    min-height: calc(100vh + 25vh);
  }
  .resetInner {
    width: 100%;
    padding-top: 250px;
  }
} */
