.multichoiceContainer .dropdown-container {
  border: none !important;
  outline: none !important;
  box-shadow: none !important;
  font-size: 12px !important;
  font-weight: 500;
  overflow: visible;
}

@media (max-width: 1200px) {
  .multichoiceContainer .dropdown-container {
    font-size: 10px !important;
    font-weight: 500 !important;
  }
  .multichoiceLight .select-item {
    font-size: 10px !important;
  }
  .multichocieDark .select-item {
    font-size: 10px !important;
  }
}
@media (max-width: 600px) {
  .multichoiceContainer .dropdown-container {
    font-size: 8px !important;
    font-weight: 300;
  }
  .multichoiceLight .select-item {
    font-size: 8px !important;
  }
  .multichocieDark .select-item {
    font-size: 8px !important;
  }
}

.dropdown-content {
  border: none !important;
  outline: none !important;
  box-shadow: none !important;
  min-width: 100px;
}

.multichoiceContainer .item-renderer {
  display: flex !important;
  border: none !important;
  align-items: center !important;
}

.multichoiceLight .dropdown-container {
  background-color: #f4f6fd !important;
  border: none !important;
}

.disabled .dropdown-heading-value span {
  color: #35407495 !important;
  font-weight: 400 !important;
  text-align: start;
}

.multichocieDark .dropdown-container {
  background-color: #252c45 !important;
}

.multichoiceLight .dropdown-heading {
  color: #354074 !important;
  font-weight: 400 !important;
  text-align: start;
  height: 40px !important;
}

.multichocieDark .dropdown-heading {
  color: #bbc5ee !important;
  font-weight: 400 !important;
  text-align: start;
  height: 40px !important;
}

.multichoiceLight .dropdown-heading-value span {
  color: rgba(53, 64, 116, 0.8);

  font-weight: 400 !important;
  text-align: start;
}

.multichocieDark .dropdown-heading-value span {
  color: #d5e7fa !important;
  font-weight: 400 !important;
  text-align: start;
}

.multichoiceLight .dropdown-heading svg path {
  fill: #a9bcd3;
}

.multichoiceLight .panel-content {
  background-color: #f4f6fd !important;
  border: 1px solid #a9bcd3 !important;
  max-height: 200px !important;
}

.multichoiceLight .panel-content .options {
  max-height: 160px !important;
  scroll-behavior: smooth;
  scrollbar-width: thin !important; /* Firefox */
  scrollbar-color: #354074 #f4f6fd !important;
}

.multichoiceLight ::-webkit-scrollbar {
  width: 4px;
}

.multichoiceLight ::-webkit-scrollbar {
  display: block;
}

.multichoiceLight ::-webkit-scrollbar-track {
  background: #f4f6fd;
}

.multichoiceLight ::-webkit-scrollbar-thumb {
  background: #354074;
  border-radius: 0 0 12px 12px;
}

.multichocieDark ::-webkit-scrollbar {
  width: 4px;
}

.multichocieDark ::-webkit-scrollbar {
  display: block;
}

.multichocieDark ::-webkit-scrollbar-track {
  background: #181f3a;
}

.multichocieDark ::-webkit-scrollbar-thumb {
  background: #a9bcd3;
  border-radius: 0 0 12px 12px;
}

.multichocieDark .panel-content {
  background-color: #252c45 !important;
  max-height: 200px !important;
}

.multichocieDark .panel-content .options {
  max-height: 160px !important;
  scroll-behavior: smooth;
  scrollbar-width: thin !important; /* Firefox */
  scrollbar-color: #a9bcd3 #181f3a !important;
}

.multichoiceLight .select-item {
  color: #354074 !important;
  font-size: 12px !important;
}

.multichocieDark .select-item {
  color: white !important;
  font-size: 12px !important;
}

.multichocieDark .select-item[aria-selected="true"] {
  background-color: #181f3a !important;
}

.multichoiceLight .select-item[aria-selected="true"] {
  background-color: #59638b26 !important;
}

.multichocieDark .select-item[aria-selected="false"]:focus {
  background-color: unset !important;
}

.multichoiceLight .select-item[aria-selected="false"]:focus {
  background-color: unset !important;
}

.multichocieDark .select-item:hover {
  background-color: #181f3a !important;
}

.multichoiceLight .select-item:hover {
  background-color: white !important;
}

.multichoiceLight .search {
  border-bottom: 1px solid #a9bcd3 !important;
}

.multichocieDark .search {
  border-bottom: 1px solid #59638b !important;
}

.multichocieDark .search input {
  background-color: #252c45 !important;
  color: white;
}

.multichoiceLight .search input {
  background-color: #f4f6fd !important;
  color: #59638b;
}

.search input::placeholder {
  color: #59638b;
}

.multichocieDark .search input::placeholder {
  color: #bbc5ee;
  opacity: 1;
}

.dropdown-content .no-options {
  color: #bbc5ee !important;
}

.multichoiceLight .select-checkbox {
  border: 2px solid var(--bs-light-text);
  width: 20px;
  height: 20px;
  min-width: 20px;
  min-height: 20px;
  transition: all 0.2s ease-in-out;
}

.multichoiceLight .select-checkbox * {
  transition: all 0.2s ease-in-out;
}

.multichoiceLight .select-checkbox svg {
  width: 10px;
  opacity: 0;
}

.multichoiceLight .select-checkbox.checked svg {
  opacity: 1;
}

.multichoiceLight .select-checkbox.checked {
  background-color: var(--bs-light-text);
}

.multichocieDark .select-checkbox {
  border: 2px solid var(--bs-light-text);
  width: 20px;
  height: 20px;
  min-width: 20px;
  min-height: 20px;
  transition: all 0.2s ease-in-out;
}

.multichocieDark .select-checkbox * {
  transition: all 0.2s ease-in-out;
}

.multichocieDark .select-checkbox svg {
  width: 10px;
  opacity: 0;
}

.multichocieDark .select-checkbox.checked svg {
  opacity: 1;
}

.multichocieDark .select-checkbox.checked {
  background-color: var(--bs-light-text);
}

.select-panel .search {
  display: flex;
  align-items: center;
  padding-left: 10px;
  padding-right: 10px;
}

.multichoiceLight .select-panel .search input::placeholder {
  opacity: 0.3;
}

.select-panel .search::before {
  content: "";
  background: url("../Common/Icons/SearchIconLight.svg");
  background-size: contain;
  background-repeat: no-repeat;
  width: 20px;
  height: 20px;
}

.select-checkbox.single {
  border-radius: 50% !important;
}

.multichoiceLight.filters .dropdown-container {
  background-color: transparent !important;
  border: 1px solid #f4f6fd !important;
  height: 40px !important;
}

.multichoiceLight.filters .dropdown-container[aria-expanded="true"] {
  border: 1px solid #354074 !important;
}

.multichoiceLight.filters .dropdown-container svg {
  width: 16px;
}

.multichoiceLight.filters .dropdown-container[aria-expanded="true"] svg path {
  fill: #bbc5ee;
}

.multichocieDark.filters .dropdown-heading-value span {
  color: #bbc5ee !important;
}

.multichocieDark.filters .dropdown-container {
  background-color: transparent !important;
  border: 1px solid #252c45 !important;
  height: 40px !important;
}

.multichocieDark.filters .dropdown-container svg {
  width: 16px;
}

@media (max-width: 600px) {
  .multichoiceLight.filters .dropdown-heading-value span {
    display: none;
  }
}
