.carousel.carousel-slider {
  overflow: visible;
}

.carousel .slider-wrapper.axis-horizontal .slider .slide {
  display: flex;
  justify-content: center;
  height: 750px !important;
}

.carousel .control-dots {
  bottom: -50px !important;
}

.carousel .control-dots .dot {
  width: 12px !important;
  height: 12px !important;
  border-radius: 8px !important;
  background: white !important;
  box-shadow: none !important;
}

.carousel .control-dots .dot.selected {
  background: #1c6ade !important;
}

.carousel-left-arrow {
  background-color: #3365ff;
  border-radius: 50%;
  position: absolute;
  top: 50%;
  left: -50px;
  width: 35px;
  height: 35px;
}

.carousel-left-arrow:hover,
.carousel-right-arrow:hover {
  background-color: transparent;
  border: 2px solid #3365ff;
  transform: scale(1.1);
  transition: all 0.2s ease-in-out;
}

.carousel-right-arrow {
  background-color: #3365ff;
  border-radius: 50%;
  position: absolute;
  top: 50%;
  right: -50px;
  width: 35px;
  height: 35px;
}
