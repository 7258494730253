.listHeaderContainer {
  z-index: 1;
  position: relative;
}

.title-font-size {
  font-size: 24px;
}

.newAlertTooltip {
  border: 1px solid #a9bcd3;
}

.newAlertTooltip:focus {
  outline: none;
}

.newAlertTooltip:before {
  content: " ";
  height: 0;
  position: absolute;
  width: 0;
  right: -20px;
  border: 10px solid transparent;
  border-left-color: #a9bcd3;
}

@media (max-width: 1200px) {
  .title-font-size {
    font-size: 20px;
  }
}
@media (max-width: 992px) {
  .title-font-size {
    font-size: 18px;
  }
}
