@media (max-width: 600px) {
  .my-modal {
    width: calc(100vw - 50px) !important;
  }
}
.modal-type {
  display: flex;
  justify-content: center;
}

.modal-dialog {
  min-width: fit-content !important;
}

.my-modal {
  width: 600px;
}

.alertModal {
  width: 800px !important;
}
