.simulateurCategorylist {
  background-color: white;
  margin-right: 15px;
  border-radius: 5px;
  overflow: scroll;
}
.simulateurQuestionlist {
  background-color: white;
  border-radius: 5px;
  overflow: auto;
  margin-left: 1px;
  width: 100%;
}
.iconContainer {
  width: 40px;
  height: 40px;
}

.categoryPosition {
  display: flex;
  width: 32px;
  height: 32px;
  background-color: #fff;
  border-radius: 100%;
  align-items: center;
  justify-content: center;
}
.selectedCategory {
  border: 2px solid #3365ff;
}
.categoryInput {
  outline: none;
}

input[type="number"] {
  -moz-appearance: textfield;
}
input[type="date"]::placeholder {
  color: #a9bcd3;
}

input[type="checkbox"] {
  /* -webkit-appearance: none; */
  height: 20px;
  width: 20px;
  outline: none;
  margin-top: 2px;
  border: 1px solid #59638b;
  border-radius: 5px;
  background-color: #ffffff;
}

.checked {
  background-color: #3365ff;
}
input[type="radio"] {
  -webkit-appearance: none;
  height: 24px;
  width: 24px;
  border-radius: 50%;
  outline: none;
  border: 1.5px solid #a9bcd3;
  background-color: #f8f9ff;
}

input[type="radio"]:before {
  content: "";
  display: block;
  width: 16px;
  height: 16px;
  margin: 14% auto;
  border-radius: 50%;
}

input[type="radio"]:checked:before {
  background: #3365ff;
  border: 1.5px solid #3365ff;
}

.textAreaInput::placeholder {
  color: #354074;
}

.textAreaInput {
  -webkit-appearance: none;
  border: none;
  outline: none;
  border: none;
  border-radius: 5px;
  resize: none;
  color: #354074;
}
.textAreaInput:focus {
  transform: scale(1.01);
}
