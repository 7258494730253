.thumbnailContainer {
  border-radius: 5px;
  background-size: cover;
  background-repeat: no-repeat;
}
.thumbnailRounded {
  border-radius: 50%;
}

.history-thumbnail {
  border-radius: 50%;
}
.connectedThumbnail {
  width: 15px;
  height: 15px;
  border: 2px solid white;
  border-radius: 50%;
  position: absolute;
  bottom: 0;
  right: 0;
  z-index: 0;
}
.userBorderedThumbnail {
  border: 4px solid white; /* Inner white border */
  outline: 2px solid #3365ff; /* Outer blue border */
  outline-offset: -2px; /* Adjust the distance between borders */
}
