.commonModeContainer {
  transition: all 0.2s ease-in-out, box-shadow 0.2s ease-in-out;
}

.lightListCard:focus {
  background-color: #f4f6fd !important;
  box-shadow: inset 0px 0px 0px 2px #3365ff;
  border-radius: 5px;
}
.lightListCardFocus {
  background-color: #f4f6fd !important;
  box-shadow: inset 0px 0px 0px 2px #3365ff;
  border-radius: 5px;
}

.darkListCard:focus {
  background-color: #181f3a81 !important;
  box-shadow: inset 0px 0px 0px 2px #3365ff;
  border-radius: 5px;
}
.darkListCardFocus {
  background-color: #181f3a81 !important;
  box-shadow: inset 0px 0px 0px 2px #3365ff;
  border-radius: 5px;
}

.lightHover:hover {
  background-color: #f4f6fd !important;
}

.darkHover:hover {
  background-color: #0a122e !important;
}

.lightHover2:hover {
  background-color: #f4f6fd93 !important;
}

.darkHover2:hover {
  background-color: #0a122e93 !important;
}
