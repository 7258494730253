.tooltiptext {
  visibility: hidden;
  border-radius: 6px;
  position: absolute;
  z-index: 1;
  top: 100%;
  /* left: 20px; */
  margin-left: -35px;
  /* width: max-content; */
  padding: 0 10px;
}

.tooltiptextLeft {
  left: 20px;
  max-width: 400px;
}

.tooltiptextRight {
  right: 0px;
  min-width: 200px;
  text-align: center;
}

.customtooltip:hover .tooltiptext,
.tooltiptext:hover {
  visibility: visible;
}

.customtooltip-light {
  background-color: #0a122e;
  color: white;
  font-weight: 500;
}

.customtooltip-light:after {
  border-color: transparent transparent #d8d8d8 transparent;
}

.customtooltip-dark {
  background-color: #252c45;
  color: #59638b;
  font-weight: 500;
}

.customtooltip-dark:after {
  border-color: transparent transparent #20194ab5 transparent;
}
