.alertList {
  width: 500px !important;
  height: 600px !important;
  top: 60px;
  right: 0;
  box-shadow: 0 6px 20px 0 rgba(7, 13, 36, 0.15) !important;
}

@media (max-width: 600px) {
  .alertList {
    width: 300px !important;
    height: 500px !important;
    right: -220px;
  }
}
.lightAlertList {
  border: 1px solid #a9bcd3;
}

.darkAlertList {
  border: 1px solid #262c45;
}

.image-alert {
  background-repeat: no-repeat;
  background-position: center;
  background-size: 100% 100%;
  height: 255px;
}
