.react-pdf__Page__canvas {
  margin: 0 !important;
}

.pdfModal .react-pdf__Page__canvas,
.react-pdf__Page__annotations,
.react-pdf__Page__textContent {
  display: none;
  /* height: 630px !important; */
}

/* @media (max-width: 1080px) or (max-height: 815px) {
  .pdfModal .react-pdf__Page__canvas,
  .react-pdf__Page__annotations,
  .react-pdf__Page__textContent {
     display: none;
    width: 80vw !important;
    height: 500px !important; 
  }
} */

.invoiceFileCard {
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  background-color: #c4c4c4;
}

.invoiceCard:focus {
  background-color: #ffffff51 !important;
  box-shadow: inset 0px 0px 0px 2px #3365ff;
  border-radius: 5px;
}
