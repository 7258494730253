.layoutRight {
  flex: 1;
  height: 100%;
  padding: 0 10px;
  overflow: hidden;
}
@media (max-width: 600px) {
  .layoutRight {
    padding: 0 10px;
  }
}
.layoutApp {
  flex: 1;
  overflow-x: hidden !important;
  overflow-y: scroll !important;
  /* height: 100vh; */
}
