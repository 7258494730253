.notificationList {
  width: 400px !important;
  height: 450px !important;
  top: 60px;
  right: 0;
  box-shadow: 0 6px 20px 0 rgba(7, 13, 36, 0.15) !important;
}

@media (max-width: 600px) {
  .notificationList {
    width: 250px !important;
    right: -120px;
  }
}
.lightNotificationList {
  border: 1px solid #a9bcd3;
}

.darkNotificationList {
  border: 1px solid #262c45;
}

.lightReadNotif {
  background-color: #f4f6fd;
  color: #354074;
}

.darkReadNotif {
  background-color: #0a122e;
  color: white;
}

.readNotif-btn:hover {
  background-color: #3365ff !important;
  color: white;
  transform: scale(1.01);
}
.markReadBtn {
  position: sticky;
  top: 2px;
}
.modal-sm {
  margin: 20px 20px 0 0;
  position: absolute;
  right: 0;
}

.modal-backdrop.show {
  opacity: 0;
}
