.customRange {
  height: 6px !important;
  border: none !important;
  box-shadow: none !important;
}
.customRangedisabled {
  opacity: 0.1;
}
.lightRange {
  background-color: #f4f6fd !important;
}
.darkRange {
  background-color: #252c45 !important;
}

.rangeslider {
  margin: 10px 0 20px 0;
}

.rangeslider__fill {
  background: #3365ffb3 !important;
  box-shadow: none !important;
}
.rangeslider__handle {
  background-color: #3365ff !important;
  box-shadow: none !important;
  border: none !important;
  width: 20px !important;
  height: 20px !important;
}
.rangeslider__handle::after {
  background-color: transparent !important;
  box-shadow: none !important;
}

.customTangeGrid {
  background-color: red;
  width: 100%;
  height: 40px;
}
.GridEltContainer {
  width: 1px;
}
.rangeGridElt {
  width: 1px !important;
  overflow: hidden;
}
.shortGridElt {
  height: 11px;
}
.longGridElt {
  height: 16px;
}
.lightGridElt {
  background-color: black;
}
.darkGridElt {
  background-color: white;
}
