.inMessageDarkInputInput {
  height: 48px;
  border: none;
  outline: none;
  background-color: #181f3a;
  color: white;
}

.inMessageLightInputInput {
  height: 48px;
  border: none;
  outline: none;
  background-color: #f4f6fd;
  color: #59638b;
}

.inMessageLightInputInput::placeholder {
  color: #59638b;
}

.inMessageDarkInputInput::placeholder {
  color: #414a6e;
}

.inMessageInputFile {
  background-color: transparent;
  transition: all 0.2s ease-in-out;
  color: #86909c;
}

#inMessageFile {
  display: none;
}
