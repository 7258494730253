.attachUploaderButton {
  display: none;
}

.attachUploadLabel {
  position: relative;
  border-radius: 10px;
  cursor: pointer;
}

.darkAttachUploadLabel {
  border: 2px dashed #354074;
  background: #252c45;
}

.lightAttachUploadLabel {
  border: 2px dashed #59638b;
  background: #f4f6fd;
}

.attachFilesText {
  color: #59638b;
  font-weight: 500;
}

.attachUploaderLoadingbar {
  height: 100%;
  background-color: #252c45;
  position: absolute;
  left: 0;
  transition: all 0.2s ease-in-out;
}

/************ COLOR PER TYPE **************/

/* .attachUploadLabelIntervention {
  border: 2px dashed #354074;
  background: #252c45;
} */

/* .attachFilesTextIntervention {
  color: white !important;
} */
