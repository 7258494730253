.externalLoaderContainer {
  position: absolute;
  bottom: 40px;
  z-index: 20;
  right: 20px;
  /* min-width: 256px;*/
  width: 30vw;
  max-height: 400px;
}
.externalLoadingItem {
  border: 1px solid rgba(53, 64, 116, 0.2);
}
.externalLoadingList {
  background-color: #fafafa;
  max-height: 300px;
  overflow: scroll;
}
.cloudActionsMenu {
  z-index: 6;
  position: absolute;
  top: -10px;
  background-color: transparent;
  pointer-events: none;
  display: flex;
  justify-content: center;

  height: 100%;
  width: 100%;
}
.cloudActionsMenuInner {
  box-shadow: 0px 6px 20px 0px #070d2426;
  min-width: 500px;
  position: sticky;
  top: 0;
  height: 56px;
  pointer-events: visible;
}
