.uploadContainer {
  width: 150px;
  height: 150px;
  cursor: pointer;
}
.roundedUploader {
  border-radius: 50%;
}

.squaredUploader {
  border-radius: 8px;
}

.uploadContainer .preview {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
  height: 100%;
}

.uploadContainer.has-preview::after {
  content: "";
  background-color: #252c45;
  opacity: 0;
  z-index: 1;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
  height: 100%;
  transition: opacity 0.2s ease-in-out;
}

.uploadContainer .z-index-1 {
  z-index: 2;
}

.uploadContainer img {
  object-fit: cover;
}

.uploadContainer.has-preview .icon,
.uploadContainer.has-preview .text {
  opacity: 0;
  transition: opacity 0.2s ease-in-out;
}

.uploadContainer.has-preview:hover::after {
  opacity: 0.5;
}
.uploadContainer.has-preview:hover .icon,
.uploadContainer.has-preview:hover .text {
  opacity: 1;
}
