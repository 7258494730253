.rightPanelEltHeight {
  max-height: 170px;
  height: 170px;
  overflow-y: scroll;
}
.rightPannelContent {
  flex: 1;
}

.underlineIcon {
  z-index: 3;
  border-bottom: 4px solid #3365ff;
  position: absolute;
  bottom: -8px;
  left: 0;
  right: 0;
  border-radius: 2px;
}
.redUnderlineIcon {
  border-bottom: 4px solid #ff7f7f;
  position: absolute;
  bottom: -8px;
  left: 0;
  right: 0;
  border-radius: 2px;
}

.tabStyle {
  cursor: pointer;
  position: relative;
  display: flex;
  align-items: center;

  justify-content: center;
}
