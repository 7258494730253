.Loadingcontainer {
  position: absolute;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100vw;
  font-family: Helvetica;
  background-color: rgb(244, 246, 253, 0.5);
  z-index: 7;
}

/* HTML: <div class="loader"></div> */
.loader {
  width: 80px;
  height: 80px;
  border-radius: 50%;
  display: inline-block;
  background-color: red;
  position: relative;
  background: linear-gradient(
    302.59deg,
    #eedef3 0%,
    #eedef3 50%,
    #efd0e1 60%,
    /* #b121ef 70%, */ #b020f0 90%
  );
  box-sizing: border-box;
  animation: rotation 1.6s linear infinite;
}

.loader::after {
  content: "";
  box-sizing: border-box;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 70px;
  height: 70px;
  border-radius: 50%;
  background: #f4f6fd;
}
@keyframes rotation {
  0% {
    transform: rotate(0deg) scale(0.9);
  }
  70% {
    transform: rotate(180deg) scale(1);
  }
  100% {
    transform: rotate(360deg) scale(0.9);
  }
}
@keyframes pulse {
  0% {
    transform: scale(0.9);
  }
  70% {
    transform: scale(0.8);
  }
  100% {
    transform: scale(0.9);
  }
}
.loaderInnerLogo {
  width: 55px;
  height: 55px;
  position: absolute;
  animation: pulse 1.6s linear infinite;
}
