.intern_note_container {
    padding: 20px
}

.intern_note_content {
    border: 1px #FF2C2C40 solid;
    background-color: #ffe5e5;
    padding: 15px 46px 15px 16px
}

.intern-msg-icon {
    top: 12px;
    bottom: 15px;
    right: 16px
}

.placeholder-input-private-msg::placeholder {
    color: #ffb7b7 !important;
    font-size: 12px
}

.internMessageCard {
    border: 1px #FF2C2C40 solid;
}