.pprodSection {
  position: fixed;
  z-index: 10;
  left: 50%;
  right: 50%;
  width: fit-content;
  margin: auto;
  text-wrap: nowrap;
  font-weight: bolder;
  transform: translateX(-50%);
}
