.lightoverlayContainer {
  background: white;
  backdrop-filter: blur(40px);
  -webkit-backdrop-filter: blur(40px);
  border: 1px solid #a9bcd3;
}

.darkoverlayContainer {
  background: #0a122e;
  /* opacity: 0.8; */
  backdrop-filter: blur(40px);
  -webkit-backdrop-filter: blur(40px);
  width: 150px !important;
  /* border: 1px solid rgba(255, 255, 255, 0.1); */
}

@media (max-width: 1200px) {
  .darkoverlayContainer {
    width: 120px !important;
  }
}

.cloudOpenMenu {
  position: absolute;
  z-index: 50;
}

.dropLeftTop {
  left: 0px;
  top: 0px;
}
.dropRightTop {
  right: 0px;
  top: 0px;
}
.dropLeftBottom {
  left: 0px;
  bottom: 25px;
}

.dropRightBottom {
  right: 0px;
  bottom: 25px;
}

.separator {
  width: 99%;
  height: 1px !important;
}

.separatorDark {
  background-color: #181f3a;
}

.separatorLight {
  background-color: #a9bcd3;
}
