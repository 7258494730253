.custom-list-tooltip-container {
  display: inline-block;
}
.custom-list-tooltip {
  top: 30px;
  z-index: 50;
  height: fit-content;
  min-width: 150px;
  padding: 8px 0;
  border-radius: 4px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
  background-color: white;
}
.tooltip-item {
  padding: 8px 16px;
  white-space: nowrap;
}
.lh-22 {
  line-height: 22px;
}
.tooltip-text {
  color: #59638b !important;
}
