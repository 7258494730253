.h240 .ql-container {
  min-height: 240px;
}
.h170 .ql-container {
  min-height: 170px;
}
.h100 .ql-container {
  min-height: 100px;
}

.ql-container {
  height: 90% !important;
}
.ql-editor .ql-emojiblot {
  /* transform: scale(1.4); */
  margin-block: 2px;
}
.ql-tooltip {
  z-index: 1 !important;
}

.lightDarkTextEditor[type="text"]::focus {
  height: 240px !important;
}
.lightDarkTextEditor .ql-container,
.ql-toolbar {
  border: none !important;
}
.lightDarkTextEditor .ql-container {
  border-radius: 0px 0px 10px 10px;
}
.lightDarkTextEditor .ql-toolbar {
  border-radius: 10px 10px 0px 0px;
}

.lightTextEditor .ql-container,
.ql-toolbar {
  background-color: #f4f6fd;
}

.lightDarkTextEditor .ql-toolbar::after {
  content: "";
  width: 100%;
  height: 1px;
  background-color: #59638b;
  margin-top: 10px;
}

/* .darkMessageEditor .ql-container,
.ql-toolbar {
  background-color: #0a122e !important;
} */

.darkTextEditor .ql-container {
  background-color: #252c45;
}
.darkTextEditor .ql-toolbar {
  background-color: #252c45;
}

.lightDarkTextEditor .ql-editor {
  /* overflow-y: scroll;
  overflow-x: hidden; */
  height: 65px;
  padding: 0 35px 8px 15px;
}

.lightDarkTextEditor .ql-editor::-webkit-scrollbar {
  display: none;
}
.lightDarkTextEditor .ql-editor::-webkit-scrollbar {
  display: none;
}

.darkMessageEditor .ql-editor {
  /* -webkit-transition: all 0.4s ease ;
  -moz-transition: all 0.4s ease;
  -o-transition: all 0.4s ease; */
  transition: all 0.2s ease-in-out;
}

.darkMessageEditor .ql-container {
  background-color: #0a122e;
}
.darkMessageEditor .ql-toolbar {
  background-color: #0a122e;
}

.lightTextEditor path {
  stroke: #59638b !important;
}
.lightTextEditor rect {
  stroke: #59638b !important;
}
.lightTextEditor polygon {
  stroke: #59638b !important;
}
.lightTextEditor line {
  stroke: #59638b !important;
}
.lightTextEditor .ql-fill {
  fill: #59638b !important;
}
.lightTextEditor .ql-picker-label::before {
  color: #59638b !important;
}
.lightTextEditor {
  color: #59638b;
}

.darkTextEditor path,
.darkMessageEditor path {
  stroke: #bbc5ee !important;
}
.darkTextEditor rect,
.darkMessageEditor rect {
  stroke: #bbc5ee !important;
}
.darkTextEditor polygon,
.darkMessageEditor polygon {
  stroke: #bbc5ee !important;
}
.darkTextEditor line,
.darkMessageEditor line {
  stroke: #bbc5ee !important;
}
.darkTextEditor .ql-fill,
.darkMessageEditor .ql-fill {
  fill: #bbc5ee !important;
}
.darkTextEditor .ql-picker-label::before,
.darkMessageEditor .ql-picker-label::before {
  color: #bbc5ee !important;
}
.darkTextEditor,
.darkMessageEditor {
  color: white;
}

.ql-snow a {
  background-color: rgb(219, 219, 219);
  padding: 3px 0;
  border-radius: 5px;
  cursor: pointer;
}

@media (max-width: 1200px) {
  .ql-snow .ql-picker.ql-header {
    width: 80px !important;
  }
  .ql-toolbar.ql-snow .ql-formats {
    margin-right: 8px;
  }
  .ql-picker {
    font-size: 12px !important;
  }
  .ql-snow.ql-toolbar button {
    height: 22px !important;
    width: 26px !important;
  }
}
@media (max-width: 992px) {
  .ql-picker {
    font-size: 10px !important;
  }
  .ql-snow.ql-toolbar button {
    height: 20px !important;
    width: 24px !important;
  }
}

.taggedUserLink {
  color: #0d6efd !important;
  text-decoration: underline !important;
}

.ql-editor {
  position: relative !important;
  width: 98%;
  padding-top: 9px !important;
}

.bi-magic:hover path {
  fill: #e67e22;
}

.mediumQuestionBox {
  width: 18px;
  height: 18px;
  font-size: 14px;
  font-weight: 600;
}
.smallQuestionBox {
  width: 14px;
  height: 14px;
  font-size: 10px;
  font-weight: 600;
}
/* .lightDarkTextEditor.auto-height .ql-toolbar.ql-snow + .ql-container.ql-snow {
  overflow: hidden;
} */

.lightDarkTextEditor.auto-height .ql-editor {
  min-height: 65px;
  height: auto !important;
  max-height: 150px !important;
}

.lightDarkTextEditor .mention {
  font-weight: 500 !important;
  background-color: #3366ff2a;
}

.lightDarkTextEditor .mention {
  cursor: pointer;
  color: #59638b;
}

.mention {
  color: #1153e2;
}

.lightDarkTextEditor.alert-height .ql-editor {
  min-height: 200px;
  height: auto !important;
  max-height: 200px !important;
}

@media screen and (max-height: 800px) {
  .lightDarkTextEditor.auto-height .ql-editor {
    height: auto !important;
    max-height: 150px !important;
  }
}

.ql-mention-list-container {
  background-color: #e3e6f1 !important;
  max-height: 190px;
  overflow-y: auto;
  scroll-snap-type: y mandatory;
  scroll-behavior: smooth;
  z-index: 20;
  border-radius: 5px;
}

.ql-mention-list-item {
  scroll-snap-align: start;
}
.lightDarkTextEditor.dark .ql-mention-list-container {
  background-color: var(--bs-dark-grey-bg) !important;
}

.ql-mention-list {
  padding: 0;
  margin-bottom: 0;
  list-style: none;
}

.ql-mention-list-item {
  padding: 0.5rem 0.75rem;
}

.lightDarkTextEditor.dark
  .ql-mention-list-container
  .ql-mention-list-item:hover {
  background-color: var(--bs-dark-mode);
}

.ql-mention-list-item:hover {
  cursor: pointer;
  background-color: var(--bs-lighter-blue);
}

.ql-mention-list-item img {
  height: 30px;
  width: 30px;
  border-radius: 50%;
  margin-right: 0.5rem;
  object-fit: cover;
  font-weight: 500;
}

.ql-mention-list-item {
  font-weight: 500;
}

.lightTextEditor > pre {
  overflow: visible !important;
}
.darkMessageEditor > pre {
  overflow: visible !important;
}
.disable-focus {
  border: none !important;
  outline: none !important;
}
.disable-focus:focus {
  border: none !important;
  outline: none !important;
}
.disable-focus:active {
  border: none !important;
  outline: none !important;
}

.guide-tooltip {
  display: none;
  appearance: none;
  outline: none;
  border: none;
}

.guide-tooltip:active {
  display: none;
  outline: none;
  border: none;
}
.guide-tooltip-span {
  width: 24px;
  height: 24px;
  border: 1px solid var(--bs-white) !important;
  margin-right: 5px;
  border-radius: 50%;
  transition: all 0.2s ease-in-out;
}

.guide-tooltip-span svg {
  display: none;
}

.guide-tooltip-span.checked svg {
  display: flex;
}
.guide-tooltip-span.checked path {
  stroke: var(--bs-white) !important;
}

/* =============================================================
============================== EMOJIS ==========================*/

.react-picker-container {
  position: absolute !important;
  top: -370px;
  right: 0px;
  z-index: 200;
  box-shadow: 0px 4px 35px 0px rgba(0, 0, 0, 0.2);
}

img[src*="https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/"]
{
  /* Your styles here */
  width: 20px;
  height: 20px;
  margin-bottom: 4px;
}
.happyEmoji:hover path {
  transform: scale(1.04);
  transform-origin: center center;
}

/* =============================================================
============================== PRIVATE MESSAGES ==========================*/

.privateTextEditor .ql-container {
  background-color: #fee2e2;
}
.privateTextEditor .ql-toolbar {
  background-color: #fee2e2;
}
.privateTextEditor path {
  stroke: #59638b !important;
}
.privateTextEditor rect {
  stroke: #59638b !important;
}
.privateTextEditor polygon {
  stroke: #59638b !important;
}
.privateTextEditor line {
  stroke: #59638b !important;
}
.privateTextEditor .ql-fill {
  fill: #59638b !important;
}
.privateTextEditor .ql-picker-label::before {
  color: #59638b !important;
}
.privateTextEditor {
  color: #59638b;
}
.privateTextEditor > pre {
  overflow: visible !important;
}
.privateTextEditor .ql-mention-list-container {
  background-color: #f7ecec !important;
  border: 1px solid #fa9d9d;
}
