.messagesReacts {
  position: absolute;
  bottom: 28px;
  width: calc(100% + 10px);
}

.messageReactElt {
  border: 1px solid #a9bcd3;
  width: 24px !important;
  min-width: 24px;
  height: 24px !important;
}

.ms-m1 {
  margin-left: -11px;
}

.favoriteMessage {
  border: 1px solid #f7d000 !important;
}

button[title="Show all Emojis"]::after {
  /* Your styles here */
  transform: scale(1.9);
}

.EmojiPickerReact {
  transform: scale(0.9);
}

.message-react-picker-container {
  position: absolute !important;
  bottom: 20px;
  right: -10px;
  z-index: 300000 !important;
  box-shadow: 0px 4px 35px 0px rgba(0, 0, 0, 0.4);
}

.messageReactElt svg:active {
  transform: scale(1.2);
  opacity: 0.4;
}

.favoriteSvg:hover svg * {
  stroke: #f7d000;
}

.reactList {
  overflow-y: scroll;
  height: 24px !important;
}

.reactElt {
  height: 24px;
}

.emojiListImage {
  width: 16px !important;
  height: 16px !important;
}

.reactUserList {
  position: absolute;
  bottom: calc(100% + 10px);
  width: 200px;
  z-index: 10;
  max-height: 400px;
  background-color: white;
  background-repeat: no-repeat;
  background-size: 180px 116px;
  overflow: scroll;
  box-shadow: 0px 4px 35px 0px rgba(0, 0, 0, 0.2);
}

.reactEltMeReacted {
  background-color: #e3f8ff;
}

.respondMessage {
  opacity: 0.9;
  transform: translate(-34px, -20px);
  -webkit-box-shadow: 1px 2px 14px 0px rgba(0, 0, 0, 0.17);
  -moz-box-shadow: 1px 2px 14px 0px rgba(0, 0, 0, 0.17);
  box-shadow: 1px 2px 14px 0px rgba(0, 0, 0, 0.17);
}

.textCrop {
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
}

.response-indication {
  opacity: 0.7;
  border-left: 5px solid rgb(51, 101, 255);
  transform: translateY(8px);
  border-radius: 10px 10px 0px 0px !important;
}

.internal-light {
  background-color: rgba(
    var(--bs-lighter-red-rgb),
    var(--bs-bg-opacity)
  ) !important;
  border-left: 5px #ff2c2c40 solid;
}

.internal-dark {
  background-color: rgba(
    var(--bs-light-red-rgb),
    var(--bs-bg-opacity)
  ) !important;
  border-left: 5px #ff2c2c40 solid;
}

.close-indication {
  right: 5px;
  top: 5px;
}

.toolbar-v2 .ql-toolbar {
  border-radius: 0px !important;
}

.response-content p {
  margin: 0px !important;
}
