.selectContainer {
  z-index: 500;
  max-height: 300px;
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
  box-shadow: 0px 1px 2px 0px #0000001f;
  overflow: auto;
  scrollbar-width: auto !important;
}
.selectContainer {
  -ms-overflow-style: block; /* IE and Edge */
}
.selectContainer::-webkit-scrollbar {
  display: block !important;
  width: 4px !important;
  background: #35744f !important;
}

.selectContainer {
  -ms-overflow-style: block; /* IE and Edge */
}

.selectItem:hover {
  background-color: #59638b26;
}
.select-direction-bottom {
  top: 40px;
}

.select-direction-top {
  bottom: 40px;
}
