.folderCard {
  width: 170px;
  height: 170px;
}

.addFolder {
  transition: all 0.2s ease-in-out;
}

.addFolder:hover {
  transform: scaleY(1.03);
}

.addFolder:active {
  transform: scale(1.1);
}
