.societyCardContainer {
  width: 100%;
  height: 305px;
  box-shadow: 0px 6px 50px 0px rgba(7, 13, 36, 0.05);
}
.userSquarePlaceDimension {
  width: 100%;
  height: 200px;
  box-shadow: 0px 6px 50px 0px rgba(7, 13, 36, 0.05);
  max-width: 500px;
}
.societyAvatar {
  background-position: center center;
  background-size: cover;
  box-shadow: 0.5px 7px 5.2px 1px #0000001a;
  border-radius: 50%;
}
.customIdContainer {
  background-color: #414a6e40;
  height: 24px;
  width: 50px;
}
.mainCompanyBackground {
  background-color: #ffdcc6;
}
.customRoleContainer {
  background-color: #d2ddff;
  height: 24px;
}
.childSocietiesAttachedContainer {
  background-color: rgba(255, 114, 27, 0.25);
  height: 24px;
  color: #ff721b !important;
}
.parentSocietyAttachedContainer {
  height: 48px;
  width: 50px;
}
.bottomSocietyCard {
  height: 80px !important;
}
.societyCardMiddle {
  height: 100px;
}
.linkedSocietyContainer {
  position: absolute;
  top: calc(100%);
  left: 0%;
  width: 200px;
  z-index: 20;
  box-shadow: 0px 6px 50px 0px rgba(7, 13, 36, 0.05);
}
.tabRadius {
  border-radius: 5px 5px 0px 0px;
}
.menuHeaderTabs {
  box-shadow: 0px 4px 50px 0px rgba(0, 0, 0, 0.04);
  height: 50px;
}
.grid4 {
  display: grid;
  grid-template-columns: repeat(4, 1fr); /* 4 items per row */
  column-gap: 1%; /* Spacing between items */
  row-gap: 10px;
}
.grid5 {
  display: grid;
  grid-template-columns: repeat(5, 1fr); /* 5 items per row */
  column-gap: 1%; /* Spacing between items */
  row-gap: 10px;
}
.verticalSocietyElt {
  width: 70px !important;
  height: 70px !important;
  min-height: 70px;
}

.searchSociety {
  z-index: 99;
  box-shadow: 0px 6px 20px 0px #070d2426;
  min-width: 550px;
  top: 65px;
  height: 56px;
  pointer-events: visible;
  left: 150px;
}

.searchSocietyCloseIcon {
  top: 6px;
  right: 4px;
}

.searchSocietyIconContainer {
  margin-bottom: 70px;
}

.societyViewIconBack:hover path {
  fill: #0d6efd !important;
  transition: all 0.3s ease !important;
}
