.SelectBoxCloud {
  position: absolute;
  right: 14px;
  top: 14px;
  border: 2px solid var(--bs-light-text);
  width: 24px;
  height: 24px;
  z-index: 5;
  opacity: 0;
}
.cloudFileCardContainer:hover .shadowSelect {
  opacity: 1;
}
.cloudFileCardContainer .internalCloudCheck {
  opacity: 0;
}
.cloudFileCardContainer:hover .coudImageInner {
  background-size: 108%;
}

.cloudFileCardContainer:hover .SelectBoxCloud {
  opacity: 1;
}
.ContainerSelected .shadowSelect {
  opacity: 1;
}
.ContainerSelected .coudImageInner {
  background-size: 108% !important;
}

.ContainerSelected .SelectBoxCloud {
  opacity: 1;
  background-color: var(--bs-light-blue);
  border-color: var(--bs-light-blue);
}

.shadowSelect {
  background: linear-gradient(
    241.17deg,
    #000000 -3.92%,
    rgba(0, 0, 0, 0) 42.79%
  );
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: 2;
  pointer-events: none;
  opacity: 0;
}

.coudImageInner {
  background-size: 100%;
  border-radius: 5px 5px 0px 0px !important;
  background-repeat: no-repeat;
}
.blueFileFilter {
  opacity: 0 !important;
}
.ContainerSelected .blueFileFilter {
  opacity: 1 !important;
}
.blueFileFilter {
  border: 2px solid var(--bs-light-blue);
  width: 108%;
  height: 108%;
  background-color: #3365ff0d;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 4;
  pointer-events: none;
}

.cloudFolderCardContainer:hover .shadowSelect {
  opacity: 1;
}
.cloudFolderCardContainer .internalCloudCheck {
  opacity: 0;
}
.cloudFolderCardContainer:hover .coudImageInner {
  background-size: 108%;
}

.cloudFolderCardContainer:hover .SelectBoxCloud {
  opacity: 1;
}
.ContainerSelected .internalCloudCheck {
  opacity: 1 !important;
}
